var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 264 280",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("desc", [_vm._v("Created with getavataaars.com")]),
      _c("defs", [
        _c("circle", {
          attrs: { id: "path-1", cx: "120", cy: "120", r: "120" },
        }),
        _c("path", {
          attrs: {
            id: "path-2",
            d: "M12,160 C12,226.27417 65.72583,280 132,280 C198.27417,280 252,226.27417 252,160 L264,160 L264,-1.42108547e-14 L-3.19744231e-14,-1.42108547e-14 L-3.19744231e-14,160 L12,160 Z",
          },
        }),
        _c("path", {
          attrs: {
            id: "path-silhouette",
            d: "M124,144.610951 L124,163 L128,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 L100,0 L100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z",
          },
        }),
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "Avataaar",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Avataaar/Circle",
                transform: "translate(-825.000000, -1100.000000)",
              },
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(825.000000, 1100.000000)" } },
                [
                  _vm.isCircle
                    ? [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Circle",
                              "stroke-width": "1",
                              "fill-rule": "evenodd",
                              transform: "translate(12.000000, 40.000000)",
                            },
                          },
                          [
                            _c(
                              "mask",
                              { attrs: { id: "mask-1", fill: "white" } },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#path-1" },
                                }),
                              ]
                            ),
                            _c("use", {
                              attrs: {
                                id: "Circle-Background",
                                fill: _vm.circleColorHex,
                                "xlink:href": "#path-1",
                              },
                            }),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Color/Palette/Blue-01",
                                  mask: "url(#mask-1)",
                                  fill: _vm.circleColor,
                                },
                              },
                              [
                                _c("rect", {
                                  attrs: {
                                    id: "🖍Color",
                                    x: "0",
                                    y: "0",
                                    width: "240",
                                    height: "240",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                          _c("use", { attrs: { "xlink:href": "#path-2" } }),
                        ]),
                      ]
                    : _vm._e(),
                  _c("g", { attrs: { id: "Mask" } }),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Avataaar",
                        "stroke-width": "1",
                        "fill-rule": "evenodd",
                        fill: "black",
                        mask: "url(#mask-2)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Body",
                            transform: "translate(32.000000, 36.000000)",
                          },
                        },
                        [
                          _c(
                            "mask",
                            { attrs: { id: "mask-silhouette", fill: "white" } },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#path-silhouette" },
                              }),
                            ]
                          ),
                          _c("use", {
                            attrs: {
                              fill: _vm.skinColors[_vm.skinColorValue],
                              "xlink:href": "#path-silhouette",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              id: "Neck-Shadow",
                              d: "M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z",
                              "fill-opacity": "0.100000001",
                              fill: "#000000",
                              mask: "url(#mask-silhouette)",
                            },
                          }),
                        ]
                      ),
                      _c("svg", {
                        style: _vm.cssVars,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.clothesType[_vm.clotheTypeValue]
                          ),
                        },
                      }),
                      _vm.clotheTypeValue === "GraphicShirt"
                        ? _c("svg", {
                            style: _vm.cssVars,
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.GraphicShirtTypes[_vm.graphicTypeValue]
                              ),
                            },
                          })
                        : _vm._e(),
                      _c("svg", {
                        domProps: {
                          innerHTML: _vm._s(_vm.eyeTypes[_vm.eyeTypeValue]),
                        },
                      }),
                      _c("svg", {
                        domProps: {
                          innerHTML: _vm._s(_vm.mouthTypes[_vm.mouthTypeValue]),
                        },
                      }),
                      _c("svg", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.eyebrowTypes[_vm.eyebrowTypeValue]
                          ),
                        },
                      }),
                      _c("svg", [
                        _c(
                          "g",
                          {
                            attrs: {
                              fill: "black",
                              transform: "translate(76.000000, 82.000000)",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Nose/Default",
                                  transform: "translate(28.000000, 40.000000)",
                                  opacity: "0.16",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Nose",
                                    d: "M16,8 C16,12.418278 21.372583,16 28,16 L28,16 C34.627417,16 40,12.418278 40,8",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("svg", {
                        style: _vm.cssVars,
                        domProps: {
                          innerHTML: _vm._s(_vm.topTypes[_vm.topTypeValue]),
                        },
                      }),
                      _c("svg", {
                        style: _vm.cssVars,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.facialHairTypes[_vm.facialHairTypeValue]
                          ),
                        },
                      }),
                      _c("svg", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.accessoriesTypes[_vm.accessoriesTypeValue]
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }