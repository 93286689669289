import { mouthTypes } from '../assets/svg/avatar/mouth';
import { eyeTypes } from '../assets/svg/avatar/eyes';
import { eyebrowTypes } from '../assets/svg/avatar/eyebrows';
import { clothesType } from '../assets/svg/avatar/clothes';
import { topTypes } from '../assets/svg/avatar/top';
import { accessoriesTypes } from '../assets/svg/avatar/accessories';
import { facialHairTypes } from '../assets/svg/avatar/facial-hair';
import { GraphicShirtTypes } from '../assets/svg/avatar/graphic-shirt';
import { hairColors, skinColors, hatAndShirtColors } from '../assets/svg/avatar/colors';

function getDefaultAvatar(seed) {
    // create a repeatable default avatar by studentId
    // PRNG: https://stackoverflow.com/a/47593316
    const fetus = cyrb128(`${seed}`);
    // Four 32-bit component hashes provide the seed for sfc32.
    const randomizer = sfc32(fetus[0], fetus[1], fetus[2], fetus[3]);

    return {
        circleColor: randomItemFromArray(Object.keys(hatAndShirtColors), randomizer()),
        topType: randomItemFromArray(Object.keys(topTypes), randomizer()),
        accessoriesType: randomItemFromArray(Object.keys(accessoriesTypes), randomizer()),
        facialHairType: randomItemFromArray(Object.keys(facialHairTypes), randomizer()),
        clotheType: randomItemFromArray(Object.keys(clothesType), randomizer()),
        eyeType: randomItemFromArray(Object.keys(eyeTypes), randomizer()),
        eyebrowType: randomItemFromArray(Object.keys(eyebrowTypes), randomizer()),
        mouthType: randomItemFromArray(Object.keys(mouthTypes), randomizer()),
        skinColor: randomItemFromArray(Object.keys(skinColors), randomizer()),
        graphicType: randomItemFromArray(Object.keys(GraphicShirtTypes), randomizer()),
        hairColor: randomItemFromArray(Object.keys(hairColors), randomizer()),
        facialHairColor: randomItemFromArray(Object.keys(hairColors), randomizer()),
        topColor: randomItemFromArray(Object.keys(hatAndShirtColors), randomizer()),
        clotheColor: randomItemFromArray(Object.keys(hatAndShirtColors), randomizer()),
    };
}


function randomItemFromArray(array, random) {
    return array[Math.floor(random * array.length)];
}

function cyrb128(str) {
    let h1 = 1779033703; let h2 = 3144134277;
    let h3 = 1013904242; let
        h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);

    h1 ^= (h2 ^ h3 ^ h4), h2 ^= h1, h3 ^= h1, h4 ^= h1;
    return [h1 >>> 0, h2 >>> 0, h3 >>> 0, h4 >>> 0];
}


function sfc32(a, b, c, d) {
    return function () {
        a |= 0; b |= 0; c |= 0; d |= 0;
        const t = (a + b | 0) + d | 0;
        d = d + 1 | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
    };
}

export {
    getDefaultAvatar as default
}

