<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable max-len -->

<template>
<svg
    viewBox="0 0 264 280"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
>
    <desc>Created with getavataaars.com</desc>
    <defs>
        <circle
            id="path-1"
            cx="120"
            cy="120"
            r="120"
        />
        <path
            id="path-2"
            d="M12,160 C12,226.27417 65.72583,280 132,280 C198.27417,280 252,226.27417 252,160 L264,160 L264,-1.42108547e-14 L-3.19744231e-14,-1.42108547e-14 L-3.19744231e-14,160 L12,160 Z"
        />
        <path
            id="path-silhouette"
            d="M124,144.610951 L124,163 L128,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 L100,0 L100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z"
        />
    </defs>
    <g
        id="Avataaar"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
    >
        <g
            id="Avataaar/Circle"
            transform="translate(-825.000000, -1100.000000)"
        >
            <g transform="translate(825.000000, 1100.000000)">
                <template v-if="isCircle">
                    <g
                        id="Circle"
                        stroke-width="1"
                        fill-rule="evenodd"
                        transform="translate(12.000000, 40.000000)"
                    >
                        <mask id="mask-1" fill="white">
                            <use xlink:href="#path-1" />
                        </mask>
                        <use
                            id="Circle-Background"
                            :fill="circleColorHex"
                            xlink:href="#path-1"
                        />
                        <g
                            id="Color/Palette/Blue-01"
                            mask="url(#mask-1)"
                            :fill="circleColor"
                        >
                            <rect
                                id="🖍Color"
                                x="0"
                                y="0"
                                width="240"
                                height="240"
                            />
                        </g>
                    </g>
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-2" />
                    </mask>
                </template>
                <g id="Mask" />
                <g
                    id="Avataaar"
                    stroke-width="1"
                    fill-rule="evenodd"
                    fill="black"
                    mask="url(#mask-2)"
                >
                    <g id="Body" transform="translate(32.000000, 36.000000)">
                        <mask id="mask-silhouette" fill="white">
                            <use xlink:href="#path-silhouette" />
                        </mask>
                        <use :fill="skinColors[skinColorValue]" xlink:href="#path-silhouette" />
                        <path
                            id="Neck-Shadow"
                            d="M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z"
                            fill-opacity="0.100000001"
                            fill="#000000"
                            mask="url(#mask-silhouette)"
                        />
                    </g>
                    <svg :style="cssVars" v-html="clothesType[clotheTypeValue]" />
                    <svg
                        v-if="clotheTypeValue === 'GraphicShirt'"
                        :style="cssVars"
                        v-html="GraphicShirtTypes[graphicTypeValue]"
                    />
                    <svg v-html="eyeTypes[eyeTypeValue]" />
                    <svg v-html="mouthTypes[mouthTypeValue]" />
                    <svg v-html="eyebrowTypes[eyebrowTypeValue]" />
                    <svg>
                        <g fill="black" transform="translate(76.000000, 82.000000)">
                            <g
                                id="Nose/Default"
                                transform="translate(28.000000, 40.000000)"
                                opacity="0.16"
                            >
                                <path
                                    id="Nose"
                                    d="M16,8 C16,12.418278 21.372583,16 28,16 L28,16 C34.627417,16 40,12.418278 40,8"
                                />
                            </g>
                        </g>
                    </svg>
                    <svg :style="cssVars" v-html="topTypes[topTypeValue]" />
                    <svg :style="cssVars" v-html="facialHairTypes[facialHairTypeValue]" />
                    <svg v-html="accessoriesTypes[accessoriesTypeValue]" />
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
import { mouthTypes } from '../assets/svg/avatar/mouth';
import { eyeTypes } from '../assets/svg/avatar/eyes';
import { eyebrowTypes } from '../assets/svg/avatar/eyebrows';
import { clothesType } from '../assets/svg/avatar/clothes';
import { topTypes } from '../assets/svg/avatar/top';
import { accessoriesTypes } from '../assets/svg/avatar/accessories';
import { facialHairTypes } from '../assets/svg/avatar/facial-hair';
import { GraphicShirtTypes } from '../assets/svg/avatar/graphic-shirt';
import { hairColors, skinColors, hatAndShirtColors } from '../assets/svg/avatar/colors';
import defaultAvatar from '../lib/defaultAvatar';

export default {
    props: {
        student: {
            type: Object,
            required: true,
        },
        studentAvatar: {
            type: Object,
            default: () => ({}),
        },
        isCircle: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            circleColor: null,
            topType: null,
            accessoriesType: null,
            facialHairType: null,
            clotheType: null,
            eyeType: null,
            eyebrowType: null,
            mouthType: null,
            skinColor: null,
            graphicType: null,
            hairColor: null,
            facialHairColor: null,
            topColor: null,
            clotheColor: null,

            mouthTypes,
            eyeTypes,
            topTypes,
            clothesType,
            eyebrowTypes,
            facialHairTypes,
            accessoriesTypes,
            GraphicShirtTypes,
            hatAndShirtColors,
            hairColors,
            skinColors,
        };
    },
    computed: {
        cssVars() {
            return {
                '--avataaar-hair-color': this.hairColorHex,
                '--avataaar-facial-hair-color': this.facialHairColorHex,
                '--avataaar-hat-color': this.hatColorHex,
                '--avataaar-shirt-color': this.shirtColorHex,
            };
        },
        circleColorHex() {
            return this.hatAndShirtColors[this.circleColor];
        },
        hairColorHex() {
            return this.hairColors[this.hairColor];
        },
        facialHairColorHex() {
            return this.hairColors[this.facialHairColor];
        },
        hatColorHex() {
            return this.hatAndShirtColors[this.topColor];
        },
        shirtColorHex() {
            return this.hatAndShirtColors[this.clotheColor];
        },
        topTypeValue() {
            return this.topType;
        },
        accessoriesTypeValue() {
            return this.accessoriesType;
        },
        facialHairTypeValue() {
            return this.facialHairType;
        },
        clotheTypeValue() {
            return this.clotheType;
        },
        eyeTypeValue() {
            return this.eyeType;
        },
        eyebrowTypeValue() {
            return this.eyebrowType;
        },
        mouthTypeValue() {
            return this.mouthType;
        },
        skinColorValue() {
            return this.skinColor;
        },
        graphicTypeValue() {
            return this.graphicType;
        },
    },
    watch: {
        studentAvatar: {
            handler() {
                this.populate();
            },
            deep: true,
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const { student } = this;

            this.circleColor = this.studentAvatar.circleColor || null;
            this.topType = this.studentAvatar.topType || null;
            this.accessoriesType = this.studentAvatar.accessoriesType || null;
            this.hairColor = this.studentAvatar.hairColor || null;
            this.facialHairType = this.studentAvatar.facialHairType || null;
            this.facialHairColor = this.studentAvatar.facialHairColor || null;
            this.clotheType = this.studentAvatar.clotheType || null;
            this.eyeType = this.studentAvatar.eyeType || null;
            this.eyebrowType = this.studentAvatar.eyebrowType || null;
            this.mouthType = this.studentAvatar.mouthType || null;
            this.skinColor = this.studentAvatar.skinColor || null;
            this.graphicType = this.studentAvatar.graphicType || null;
            this.topColor = this.studentAvatar.topColor || null;
            this.clotheColor = this.studentAvatar.clotheColor || null;

            if (!this.topType) {
                const avatar = defaultAvatar(student.studentId);
                this.topType = avatar.topType;
                this.accessoriesType = avatar.accessoriesType;
                this.hairColor = avatar.hairColor;
                this.facialHairType = avatar.facialHairType;
                this.facialHairColor = avatar.facialHairColor;
                this.clotheType = avatar.clotheType;
                this.eyeType = avatar.eyeType;
                this.eyebrowType = avatar.eyebrowType;
                this.mouthType = avatar.mouthType;
                this.skinColor = avatar.skinColor;
                this.graphicType = avatar.graphicType;
                this.topColor = avatar.topColor;
                this.clotheColor = avatar.clotheColor;
            }
        },
    },
};

</script>
